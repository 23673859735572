<template>
  <div class="preview-button">
    <button @click="openModal">
      <i class="icon-cart"></i>
      <span>{{ $t('Watch Preview') }}</span>
    </button>
    <ModalProduct :is-open="modalOpen" :data="modalData" @close="onCloseModal" />
  </div>
</template>

<script>
import ModalProduct from './ModalProduct.vue'

export default {
  props: ['modalData'],
  name: 'preview-button',
  data() {
    return {
      modalOpen: false
    }
  },
  components: {
    ModalProduct
  },
  methods: {
    openModal() {
      this.modalOpen = true
    },
    onCloseModal() {
      this.modalOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/core";

.preview-button button {
    position: absolute;
    top: 3rem;
    right: 3rem;
    height: 3.5rem;
    color: var(--zenith-black);
    border: none;
    margin: 0;
    overflow: hidden;
    padding: 1rem 1rem 1rem 0.75rem;
    border-radius: 3.5rem;
    background-color: var(--zenith-white);
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 3.5rem;

    @include bp(max md) {
      top: 1rem;
      right: 1rem;
    }

    i {
      font-size: 2rem;
    }

    span {
      white-space: nowrap;
      text-transform: uppercase;
      font-size: 1rem;
      margin-left: 1rem;
      opacity: 0;
      transition: opacity 0.3s 0s ease-in;
    }

    &:hover, &:active, &:focus {
      max-width: 20rem;
      transition: max-width 0.3s 0s ease-out;

      span {
        opacity: 1;
        transition: opacity 0.3s 0s ease-out;
      }
    }
  }
</style>
